export default {
  back: "Voltar",
  requiredInput: "Campo obrigatório",
  requiredName: "O nome é obrigatório",
  emailInputMessage: "Insira um e-mail válido!",
  all: "Todos",
  photos: "Fotos",
  videos: "Vídeos",
  texts: "Textos",
  recommendedProfiles: "PERFIS RECOMENDADOS",
  follow: "Seguir",
  followers: "Seguidores",
  following: "Seguindo",
  favoritesDescription:
    "Aqui você encontra publicações e fotos curtidas e perfis seguidos.",
  savedItems: "itens salvos",
  publications: "Publicações",
  profiles: "Perfis",
  profile: "Perfil",
  mostLiked: "MAIS CURTIDOS",
  viewMore: "Ver",
  models: "Modelos",
  essays: "Ensaios",
  searchTitle: "Encontre criadores",
  search: "Pesquisar",
  seePlans: "Ver planos",
  sorryMessage: "Desculpe, ",
  profileNotFound: "Nenhum perfil encontrado",
  pleaseMessage: "Por favor,",
  sorryMessageContinue: "não encontramos este usuário.",
  sorryMessageContinueEssay: "não encontramos este ensaio.",
  pleaseMessageContinue: "digite o nome de um criador.",
  seeAll: "Ver todos",
  nameResult: " Resultados para ",
  notFoundTitle: "Oops, nada por aqui",
  notFoundTitleTwo: "Teste",
  notFoundMessage: "Busque por modelos ou volte mais tarde ",
  notFounMessageContinue: "para poder ver mais ensaios.",
  changePhotoDescription: "Atualizar imagem",
  changePhotoDescriptionSub: "Selecione a área de corte",
  changePhotoSuccess: "Foto de perfil alterada com sucesso.",
  havePlan: "Adquirir plano",
  managePlan: "Atualizar plano",
  switchPlan: "Aterar plano",
  switchPaymentMethod: "Meio de pagamento",
  retryPayment: "Realizar novo pagamento.",
  retryPaymentSuccess: "Novo pedido enviado com sucesso!",
  retryPaymentTitle: "Retentativa de pagamento",
  retryPaymentDescription:
    "Caso seu pagamento tenha falhado por algum motivo, preencha os dados e tente novamente.",
  continueRetryPayment: "Retentar pagamento",
  payment: "Pagamento",
  cancelSubscription: "Cancelar Pedido",
  paymentHistory: "Histórico de transações",
  paymentCheck: "Consulte seus pagamentos",
  noPaymentHistory: "Nenhum pagamento foi efetuado",
  seeLess: "Ver menos",
  subscriptionDuration: "Seu acesso continuará ativo até",
  cancel: "Cancelar",
  subscriptionInfo: "Sua assinatura",
  subscriptionStatus: "Status da assinatura",
  changeCoverDescription: "Alterar foto de capa",
  changeCoverDescriptionSub: "Selecione a área de corte",
  publicEmail: "E-mail público",
  manageNameDescription: "O nome completo é um campo obrigatório.",
  managePublicEmailDescription: "Para excluir o e-mail, deixe o campo vazio.",
  manageNicknameDescription1: " O apelido substitui o nome.",
  manageNicknameDescription2: " Para excluir, deixe o campo vazio.",
  switchEmail: "E-mail",
  emailChangeSuccess: "E-mail alterado com sucesso",
  changeLaguageTitle: "Alterar idioma",
  changeLaguageDescription: "Escolha a linguagem do site",
  goToSite: "Ir para o site",
  phone: "Telefone",
  changePhone: " Alterar telefone",
  name: "Nome",
  fullName: "Nome completo",
  changeName: "Alterar nome",
  changeNameSuccess: "Nome alterado com sucesso.",
  changeDescriptionSuccess: "Descrição alterada com sucesso.",
  changeWithSuccess: "Alteração realizada com sucesso!",
  changeNickname: "Alterar apelido",
  changeNicknameSuccess: "Apelido alterado com sucesso.",
  passwordMessage: "Confirme a senha para sua segurança.",
  phoneMessage: "Insira um novo número de telefone para sua conta.",
  validateMessage: "Insira o código enviado para",
  forgotPassword: "Esqueci minha senha",
  newPassword: "Nova senha",
  buttonValidate: "Validar",
  codeResend: "reenviar em",
  placeholderPassword: "Insira sua senha",
  placeholderPhone: "Insira um novo número de telefone",
  placeholderValidate: "Ex.: 000 000",
  placeholderZicode: "Digite seu CEP",

  descriptionTitle: "Descrição",
  descriptionTitleSub: "Fale um pouco sobre você.",
  descriptionChangeSuccess: "Descrição alterada com sucesso.",
  save: "Salvar",
  socialNetworks: "Redes sociais",
  socialNetworksChangeSuccess: "Redes sociais alteradas com sucesso.",
  linkMessage: "Digite o nome de usuário sem o @.",
  buttonSave: "Salvar",
  emptyStateTitle: "Oops, nada por aqui",
  emptyStateFeedColored: "Siga modelos ou volte mais tarde",
  emptyStateFeed: "para poder ver mais posts.",
  forgotMyPassword: "Esqueci minha senha",
  "forgotMyPassword.description":
    "Informe seu telefone para receber o código de segurança.",
  "forgotMyPassword.authDescription": "Insira o código enviado para",
  telephoneNumber: "Número de telefone",
  continue: "Continuar",
  numberWithoutRegister: "Não cadastrado!",
  "numberWithoutRegister.description":
    "Confira o número informado ou crie uma nova conta para continuar",
  createAccount: "Criar conta",
  resend: "Reenviar",
  in: "em",
  changePassword: "Alterar senha",
  "changePassword.description": "Sua nova senha deve conter letras e números.",
  confirmNewPassword: "Confirmar nova senha",
  bad: "Ruim",
  medium: "Médio",
  excelent: "Excelente",
  loseAnonimattaAccess: "Não perca seu acesso ao Anonimatta.",
  "warningBill.planDescription":
    "Seu plano {plan} no valor de R$ {value} expira em:",
  "warningBill.paymentDate":
    "Para manter sua conta ativa, realize o pagamento até:",
  billNumber: "Número do boleto",
  copyCode: "Copiar código",
  editPayment: "Editar forma de pagamento ou plano",
  downloadBill: "Baixar boleto",
  billPayed: "Caso já tenha pagado, desconsidere essa mensagem.",
  generateBill: "Gerar boleto",
  expiredBill: "Desculpe seu boleto expirou",
  "expiredBill.description":
    "Seu boleto com o vencimento para o {dayText} expirou. Para ativar sua conta, gere outro boleto e realize o pagamento.",
  dayN: "dia {day}",
  newBill: "Novo boleto gerado",
  "newBill.description":
    "Seu boleto foi gerado com o vencimento para o {day}. Sua conta será ativada após a confirmação do pagamento.",
  myAccount: "Minha conta",
  exit: "Sair",
  changeLanguage: "Alterar Idioma",
  plan: "Plano",
  termsAndPrivacy: "Termos e Privacidade",
  publicData: "Dados públicos",
  coverPhoto: "Foto de capa",
  descriptionUser: "Descrição",
  "loginAdmin.first": "Insira seu usuário e senha ",
  "loginAdmin.second": "para prosseguir",
  enter: "Entrar",
  essay: "ENSAIOS",
  feed: "FEED",
  saved: "SALVOS",
  favorites: "FAVORITOS",
  post: "POST",
  account: "CONTA",
  descriptionLogin: "Insira seus dados para prosseguir.",
  phoneNumber: "Número telefone",
  password: "Senha",
  recoverPassword: "Esqueci minha senha",
  log: "Entrar",
  comments: "Comentários",
  commentRequired: "Insira um comentário",
  likes: "Curtidas",
  theaterMode: "Modo teatro",
  passwordReject: "As duas senhas que você digitou não coincidem!",
  insertEmail: "Insira seu e-mail",
  dateOfBirth: "Data de nascimento",

  nickname: "Apelido",
  optional: "opcional",
  identityDocument: "Documento de identificação",
  documentNumber: "Número do documento",
  repeatPassword: "Confirmar senha",
  editPersonal: "Editar dados pessoais.",
  creatorDocuments:
    "Tire fotos da frente e verso do documento informado na primeira etapa do cadastro.",
  creatorSelfie:
    "Agora tire uma selfie segurando o documento ao lado do rosto (os números precisam estar legíveis).",
  creatorMail: "Envie as fotos para o e-mail ",
  creatorMailTwo: "criadores@anonimatta.com.br ",
  creatorMailThree: "informando seu nome no assunto.",
  creatorActiveAccount: "Pronto! Em até 24 horas seu perfil estará ativo.",
  creatorValidated: "Entraremos em contato caso ele não seja validado.",
  publicMail: "E-mail público",
  enterNotUser: "ENTRAR",
  language: "LINGUAGEM",
  titleUser: "Conta criador",
  titleUserSub: "Preencha com as suas informaçoes pessoais.",
  titleUserDescription: "Quer apenas acompanhar as modelos e os ensaios? ",
  titleUserDescriptionSub: "Crie sua conta de assinante.",
  titleUserDescriptionSubCreate: " Ou ",
  titleUserLogin: "Entrar na conta. ",
  titlePhoto: "Fotos e mídias sociais",
  titlePhotoSub:
    "Envie suas fotos do perfil e seus contatos nas mídias sociais.",
  titleApproval: "Aguardando aprovação!",
  titleApprovalSub:
    "Para garantir a segurança do seu conteúdo e da sua privacidade, precisamos certificar que suas informações são reais.",
  profilePhoto: "Foto de perfil",
  choosePhoto: "Escolha uma foto para seu perfil",
  chooseCover: "Escolha uma foto para sua capa",
  upload: "Enviar imagem",
  changePhoto: "Trocar foto",
  changeImage: "Trocar imagem",
  changeCover: "Trocar capa",
  changeCoverSuccess: "Imagem de capa alterada com sucesso.",
  typePhone: "Insira seu número",
  typeNickname: "Digite seu apelido",
  typeDateOfBirth: "Digite sua data de nascimento",
  typeNameFull: "Digite seu nome completo",
  typeEmail: "Digite seu e-mail",
  typeStreet: "Digite a rua",
  typeNumber: "Digite o número",
  typeComplement: "Digite o complemento",
  typeDistrict: "Digite o bairro",
  selectDocument: "Selecione seu documento",
  typeDocument: "Digite o número do seu documento",
  typeNameDocument: "Digite o nome do documento",
  passwordError: "A senha deve conter letras e números",
  passport: "Passaporte",
  others: "Outros *para não brasileiros",
  creators: "Criadores",
  subscribers: "Assinantes",
  payament: "Pagamento",
  subscribe: "Assinar",
  off: "Off",
  sign: "Assinar",
  daily: "diário",
  "landingPage.daily.charge": "Cobrança diária e automática",
  monthly: "Mensal",
  "landingPage.monthly.charge": "Cobrança mensal e automática",
  quarterly: "Trimestral",
  "landingPage.quarterly.charge": "Cobrança trimestral e automática",
  halfYearly: "Semestral",
  "landingPage.halfYearly.charge": "Cobrança semestral e automática",
  annual: "Anual",
  "landingPage.annual.charge": "Cobrança anual e automática",
  subscribeContent: "Assinar conteúdo",
  wantBeCreator: "Quero ser criador",
  landingPageHeroSubtitle:
    "Acesso ilimitado a mais de 200 ensaios, 8 lançamentos todos os meses. Cancele quando quiser.",
  "landingPage.firstBanner.title": "Ensaios sensuais com {text}",
  "landingPage.firstBanner.titleText": "mulheres reais!",
  "landingPage.firstBanner.description":
    "O Anonimatta é a solução definitiva para ajudar modelos e fotógrafos(as) a monetizarem seus trabalhos de {firstText}",
  "landingPage.firstBanner.description.firstText":
    "forma segura, justa e livres de censura.",
  "landingPage.firstBanner.secondDescription":
    "Para assinantes, o Anonimatta possui um extenso catálogo com {firstText}, um acervo com {secondText}",
  "landingPage.firstBanner.secondDescription.firstText": "mais de 100 ensaios",
  "landingPage.firstBanner.secondDescription.secondText":
    "mais de 4000 mídias e várias modelos compartilhando seus conteúdos diariamente no feed público do site.",
  "landingPage.secondBanner.title": "Mais conteúdo, {text}",
  "landingPage.secondBanner.title.text": "menos photoshop",
  "landingPage.secondBanner.description":
    "Lançamento de até três novos ensaios por semana, {firstText} o Anonimatta não modifica as características físicas das modelos, {secondText}",
  "landingPage.secondBanner.description.firstTest": "e o mais importante,",
  "landingPage.secondBanner.description.secondText": `então, não esperem por fotos "photoshopadas" ou cheias de filtros.`,
  "landingPage.secondBanner.secondDescription":
    "Acreditamos que a beleza natural está acima de qualquer padrão",
  "landingPage.thirdBanner.social.title": "Rede Social",
  "landingPage.thirdBanner.social.description": "Curta, siga, comente {text}",
  "landingPage.thirdBanner.social.text":
    "ou salve fotos, vídeos ou o perfil das modelos que você mais gosta.",
  "landingPage.thirdBanner.feed.title": "Feed de conteúdo",
  "landingPage.thirdBanner.feed.description":
    "Acompanhe diariamente todas as {text} que você mais admira reunidas em um único feed.",
  "landingPage.thirdBanner.feed.text":
    "postagens públicas das criadoras de conteúdo",
  "landingPage.thirdBanner.affiliated.title": "Feed de conteúdo",
  "landingPage.thirdBanner.affiliated.description":
    "Agora qualquer assinante Anonimatta pode se tornar um afiliado. {text} sobre as vendas de qualquer plano de assinatura",
  "landingPage.thirdBanner.affiliated.text": "Ganhe até 50% de comissão",
  "landingPage.fourthBanner.money.title":
    "Ganhe dinheiro como criador de conteúdo",
  "landingPage.fourthBanner.money.description":
    "Você é fotógrafo(a) ou modelo? {text}",
  "landingPage.fourthBanner.money.text":
    "Então, cadastre-se como criador(a), crie conteúdos, venda assinaturas para seus seguidores e ganhe até 80% de comissão sobre todas suas vendas.",
  "landingPage.fourthBanner.sales.title": "Painel de vendas",
  "landingPage.fourthBanner.sales.description":
    "O Anonimatta disponibiliza um {text} para todos criadores administrarem suas vendas e comissões em tempo real, com notificações via Whatsapp.",
  "landingPage.fourthBanner.sales.text": "painel de vendas completo",
  "landingPage.fifthBanner.title": "Proteção {text}",
  "landingPage.fifthBanner.title.text": "contra vazamento de conteúdo",
  "landingPage.fifthBanner.description": `Todas as mídias compartilhadas no Anonimatta são protegidas com uma marca d${"`"}água com o número de identificação do usuário.
   {firstText} é posível identificar o usuário que fez o vazamento {secondText}`,
  "landingPage.fifthBanner.description.firstText":
    "Neste caso, se qualquer conteúdo for vazado fora do site,",
  "landingPage.fifthBanner.description.secondText":
    "e impor todas as responsabilidades legais.",
  "landingPage.plans.title": "Planos que cabem no seu bolso",
  "landingPage.plans.description":
    "Investindo a partir de R$34,90 por mês, você tem acesso a ensaios sensuais exclusivos de tirar o fôlego. {text}",
  "landingPage.plans.description.text": "Não perca tempo e assine agora!",
  "landingPage.plans.exclusiveAccess":
    "Acesso exclusivo a ensaios completos e feed de postagens. O nome do site não aparece na sua fatura.",
  "landingPage.realWomen": "Ensaios sensuais com mulheres reais",
  "landingPage.frequentlyQuestions": "Perguntas frequentes",

  "landingPage.subscriberQuestion.1": "Como funciona a assinatura no site?",
  "landingPage.subscriberAnswer.1":
    "Ao adquirir um de nossos planos de assinatura, você ganha acesso a mais de 100 ensaios disponíveis no site.",
  "landingPage.subscriberQuestion.2":
    "Quais os tipos de ensaios irei encontrar no catálogo do site?",
  "landingPage.subscriberAnswer.2":
    "Anonimatta possui três categorias de ensaios: Nude, Topless e Lingerie. 97% de todos os ensaios do catálogo são Nudes (nudez explícita).",
  "landingPage.subscriberQuestion.3": "As modelos mostram tudo nas fotos?",
  "landingPage.subscriberAnswer.3":
    "Sim, na categoria Nude as modelos exibem seus corpos sem nenhum tipo de censura.",
  "landingPage.subscriberQuestion.4": "Quantas fotos tem cada ensaio?",
  "landingPage.subscriberAnswer.4": "Cada ensaio tem entre 40 a 80 fotos.",
  "landingPage.subscriberQuestion.5":
    "Todos os ensaios disponíveis no site possuem vídeos?",
  "landingPage.subscriberAnswer.5":
    "Não, ainda existem poucos vídeos em nosso catálogo, entretanto, nossa principal meta para 2021 é aumentar a produção de making off dos ensaios.",
  "landingPage.subscriberQuestion.6":
    "É possível realizar a compra de apenas um único ensaio?",
  "landingPage.subscriberAnswer.6":
    "Não, não é possível. O Anonimatta não realiza venda individual para ensaios.",
  "landingPage.subscriberQuestion.7": "Posso indicar uma modelo?",
  "landingPage.subscriberAnswer.7":
    "Sim, você pode enviar o @rroba da modelo diretamente para nossa conta no Instagram: @anonimatta.com.br",

  "landingPage.subscriberPayamentQuestion.1":
    "Qual é a forma de pagamento aceita pelo site?",
  "landingPage.subscriberPayamentAnswer.1":
    "Sua assinatura pode ser paga de duas formas: cartão de crédito ou boleto bancário. Não aceitamos cartão débito ou transferências bancárias.",
  "landingPage.subscriberPayamentQuestion.2":
    "A cobrança será feita no meu cartão de crédito todos os meses?",
  "landingPage.subscriberPayamentAnswer.2":
    "Sim, as assinaturas são recorrentes. O seu plano será renovado automaticamente uma vez por mês no mesmo dia da adesão do seu plano, caso não queira cobranças futuras, basta cancelar sua assinatura antes da data de vencimento contratada.",
  "landingPage.subscriberPayamentQuestion.3":
    "Posso cancelar minha assinatura a qualquer momento?",
  "landingPage.subscriberPayamentAnswer.3":
    "Sim, não existe taxa de cancelamento. Você pode alterar ou cancelar seu plano a qualquer momento, caso não queira continuar com a assinatura. Para cancelar, acesse a página: Minha Conta > Plano > Gerenciar Plano > Cancelar assinatura.",
  "landingPage.subscriberPayamentQuestion.4":
    "Ao cancelar minha assinatura antes do vencimento, eu perco o acesso?",
  "landingPage.subscriberPayamentAnswer.4":
    "Não. Seu acesso fica disponível durante todo o período contratado.",
  "landingPage.subscriberPayamentQuestion.5":
    "O nome do site aparece na fatura do Cartão?",
  "landingPage.subscriberPayamentAnswer.5":
    "Não. Fique tranquilo. Na fatura do seu cartão não terá nenhuma menção a palavra Anonimatta.",
  "landingPage.subscriberPayamentQuestion.6":
    "Onde posso aplicar o cupom de desconto?",
  "landingPage.subscriberPayamentAnswer.6":
    "O código do seu cupom deve ser inserido na tela de finalização do pagamento.",
  "landingPage.subscriberPayamentQuestion.7":
    "Cupom de desconto é válido apenas para o primeiro mês?",
  "landingPage.subscriberPayamentAnswer.7":
    "Depende do cupom. Temos cupons vitalícios e cupons que garantem desconto apenas para o primeiro mês.",

  "landingPage.creatorQuestion.1":
    "Qualquer pessoa pode se cadastrar como Criador?",
  "landingPage.creatorAnswer.1":
    "Não. Aceitaremos apenas fotógrafos(as) e mulheres que trabalhem como modelo ou venda de conteúdo adulto.",
  "landingPage.creatorQuestion.2": "Preciso verificar minha identidade?",
  "landingPage.creatorAnswer.2":
    "Sim, vamos aprovar apenas perfis que enviarem uma cópia do documento com foto + selfie segurando o documento.",
  "landingPage.creatorQuestion.3":
    "Qual o motivo da verificação de identidade?",
  "landingPage.creatorAnswer.3":
    "A verificação é necessária para garantirmos apenas a aprovação de perfis com pessoas reais. Desejamos construir uma comunidade segura e livre de fraudes e perfis falsos,  dessa forma, evitamos que imagens e vídeos de terceiros sejam vendidos ou compartilhados ilegalmente dentro do Anonimatta.",
  "landingPage.creatorQuestion.4":
    "Posso ser um criador e modelo do Anonimatta ao mesmo tempo?",
  "landingPage.creatorAnswer.4":
    "Sim, você pode criar e compartilhar o seu conteúdo no feed público do site e também ter um ensaio profissional no catálogo do Anonimatta.",
  "landingPage.creatorQuestion.5": "Posso fazer propagandas no feed do site?",
  "landingPage.creatorAnswer.5":
    "É proibido fazer propaganda de produtos, serviços, política e prostituição.",
  "landingPage.creatorQuestion.6":
    "Criadores tem acesso ao catálogo de ensaios do Anonimatta?",
  "landingPage.creatorAnswer.6":
    "Sim, todos os Criadores tem acesso vitalício aos ensaios divulgados pelo Anonimatta. Você pode navegar, curtir e comentar todas as fotos dos ensaios.",

  "landingPage.footer.copyright":
    "© Todos os direitos reservados copyright 2020",
  titleSubscriber: "Conta assinante",
  titleSubscriberSub: "Preencha os campos abaixo para prosseguir.",
  titleSubscriberDescription: "É modelo ou fotógrafo? ",
  titleSubscriberDescriptionSub: "Crie sua conta de criador.",
  planUserTitle: "Escolha seu plano",
  paymentWithCardOrPix: "Pague com cartão de crédito ou PIX.",
  planUserFooter:
    "Acesso exclusivo a ensaios completos e feed de postagens. O nome do site não aparece na sua fatura.",
  paymentTitle: "Pagamento",
  paymentDescription: "Selecione a forma de pagamento e preencha os dados.",
  paymentInfo: "O nome do site não aparece na sua fatura.",
  paymentCancelAnytime: "Cancele quando quiser.",
  cardNumber: "Número do cartão",
  createAccountNew: "Conta criada com sucesso!",
  nameCard: "Nome no cartão",
  dueDate: "Venc. MM/AA",
  codeCard: "CVV",
  boletoDescriptionOne:
    "*Forma de pagamento disponível apenas para brasileiros.",
  boletoDescriptionTwo: "Seu boleto será emitido com vencimento em:",
  pixDescription:
    "Para fazer seu PIX, leia o QRCode abaixo ou copie o código para seu internet banking.",
  pixDescriptionTwo:
    "Clique no botão abaixo para gerar seu QRCode para pagamento.",
  pixGenerateQRCode: "Gerar QRCode",
  pixActivatePlanSelect: "Selecione um plano para reativar sua conta.",
  pixActivateContinueButton: "Voltar a navegar",
  pixActivateContinueText:
    "Tudo pronto! Sua conta está ativada e pronta para uso.",
  copyTextPix:
    "Ou copie o código abaixo para seu aplicativo de internet banking",
  clipboardPix:
    "Copie o código abaixo para seu aplicativo de internet banking.",
  pixExpiration: "Esta chave PIX expira em:",
  pixSuccessMessage: "Seu PIX foi processado e aprovado!",
  pixSuccessValidate: "Sua conta foi ativada e está pronta para uso!",
  paymentStatusTitle: "Conta criada com sucesso!",
  accountCreated: "Faça o login e verifique o status do seu pagamento!",
  paymentStatusDescriptionOne: "Pagamento efetuado com sucesso!",
  paymentStatusDescriptionMonthly: " A cobrança é automática todo dia {day}",
  paymentStatusDescription:
    " A próxima cobrança será automática no dia {day}/{month}/{year}",
  paymentStatusDescriptionBillet:
    " A próxima cobrança será automática no dia {day}/{month}/{year} e você receberá um e-mail com o novo boleto gerado",
  paymentStatusDescriptionTwo: " e você pode cancelar quando quiser.",
  goSite: "Acessar site",
  errorCharge: "Erro de cobrança",
  errorChargeDescriptionOne:
    "Não foi possivel renovar sua assinatura para o cartão de final:",
  errorChargeDescriptionTwo: "Recupere seu acesso ao Anonimatta!",
  errorChargeDescriptionThree: "Editar forma de pagamento ou plano",
  tryAgain: "Tentar novamente",
  or: "ou ",
  avatarUse: "use um avatar.",
  avatar: "Avatar",
  error: "Erro",
  errorMessage: "Tente outra vez!",
  avatars: "Avatares",
  avatarsDescription: "Escolha um avatar que te represente.",
  tooltipMessage: "Item opcional",
  "tooltipMessage.nickname":
    "Caso não queira divulgar seu nome completo no site, preencha o apelido.",
  "tooltipMessage.whatsapp": "DDD + 9 + seu número do whatsapp",
  "tooltipMessage.username":
    "O nome de usuário funciona como se fosse seu @rroba. Escolha com cuidado, depois não é possivel alterar.",
  "tooltipMessage.coupon": "Cupom válido apenas para cartão de crédito.",
  country: "País",
  instagram: "Instagram",
  twitter: "Twitter",
  whatsapp: "Whatsapp",
  digitNine: "com digito 9",
  selectCountry: "Selecione seu país",
  selectCreatorType: "Selecione o seu tipo de perfil",
  selectStateBrazil: "Selecione o seu estado",
  selectCityBrazil: "Selecione a sua cidade",
  placeholderState: "Digite o nome do seu estado",
  placeholderCity: "Digite o nome da sua cidade",
  whatDocument: "Qual é o documento",
  messageErrorPassword: "A senha deve ter no mínimo 6 caracteres!",
  creditCard: "Cartão de crédito",
  billet: "Boleto",
  pix: "PIX",
  state: "Estado ",
  city: "Cidade ",
  iAm: "Eu sou",
  model: "Modelo",
  photographer: "Fotógrafo",
  modelRadio: "Eu sou modelo",
  photographerRadio: "Eu sou fotógrafo",
  errorPhoto: "É necessário adicionar uma foto para seu perfil",
  errorCover: "É necessário adicionar uma capa para seu perfil",
  invalidDate: "Data inválida",
  errorFullName: "Informe nome e sobrenome válidos",
  errorOnlyCharacters: "É permitido somente letras neste campo.",
  errorOnlyNumbers: "É permitido somente números neste campo.",
  ageValidation: "Proibido para menores de 18 anos",
  errorEmail: "Você não digitou um e-mail válido!",
  errorYear: "Ano de nascimento inválido",
  errorCurrentYear: "Ano de nascimento deve ser menor que o ano atual",
  anonimattaExclusiveContent: "Conteúdo exclusivo Anonimatta.",
  "anonimattaExclusiveContent.description":
    "Faça sua assinatura no Anonimatta para ver todos os ensaios.",
  "anonimattaExclusiveContent.button": "Ver planos Anonimatta",
  creatorExclusiveContent: "Conteúdo exclusivo do criador.",
  "creatorExclusiveContent.description":
    "Escolha um plano para ver todas as publicações de @{name}.",
  "creatorExclusiveContent.button": "Ver planos do criador",
  "profile.emptyState": "Essa modelo ainda não possui publicações ou ensaios",
  "profile.emptyStatePosts": "Essa modelo ainda não possui publicações",
  "profile.emptyStateEssays": "Essa modelo ainda não possui ensaios",
  errorDocumentNumber: "CPF inválido",
  errorPassportNumber: "Número de passaporte inválido",
  errorPhoneNumber: "Número inválido",
  cardNumberError: "Número do cartão inválido",
  errorCardName: "Informe um nome válido",
  errorCardCode: "CVV inválido",
  email: "E-mail",
  street: "Rua",
  number: "Número",
  complement: "Complemento",
  district: "Bairro",
  zipcode: "CEP",
  brazil: "Brasil",
  errorCardCodeNumberCreditCard: "Informe o número do cartão primeiro",
  billetLink: "Acesse aqui o boleto",
  goToHome: "Ir para página inicial",
  errorCPF: "CPF inválido",
  errorRG: "RG inválido",
  errorCNH: "CNH inválido",
  errorPassaport: "Passaporte inválido",
  errorLogin:
    "Usuário ou senha incorretos, por favor, verifique os campos digitados.",
  "pageNotFound.title": "Página não encontrada",
  "pageNotFound.text":
    "Oops! A página que você está procurando foi removida ou realocada.",
  "pageNotFound.goBack": "Voltar",
  portuguese: "Português",
  spanish: "Espanhol",
  english: "Inglês",
  avatarUserProfileAlt: "Imagem do perfil do usuário",
  "newPost.title": "Nova publicação",
  "newPost.descriptionPlaceholder": "O que você quer compartilhar?",
  "newPost.uploadFile": "Enviar mídia",
  "newPost.changeImageOrVideo": "Trocar mídia",
  "newPost.buttonSubmit": "Publicar",
  "newPost.successMessage": "Post publicado com sucesso",
  errorZipCode: "Cep Inválido!",
  yearValidation: "Data inválida",
  loading: "Carregando ...",
  processing: "Processando...",
  loadingProfile: "Carregando perfil ...",
  loadingPosts: "Carregando posts ...",
  loadingEssays: "Carregando ensaios ...",
  loadingMorePosts: "Carregando mais posts ...",
  loadingMoreEssays: "Carregando mais ensaios ...",
  notFoundEssay: "Ensaio não encontrado",
  nude: "Nude",
  topless: "Topless",
  lingerie: "Lingerie",
  verifyNumber: "Verificar número",
  invalidCode: "Código inválido",
  validatePhoneError:
    "Para continuar, é necessário validar o código recebido no seu whatsapp. Preencha novamente o campo de telefone e tente novamente",
  validatePhoneSuccess: "Código validado com sucesso",
  changePasswordSuccess: "Senha alterada com sucesso",
  toClean: "Limpar",
  termsAndPrivacy2: "Aceito os ",
  term: "Termos",
  and: " e ",
  privacy: "privacidade",
  validateTermsAndPrivacy: "Você precisa aceitar os termos para continuar.",
  validatePhoneUnique: "Número já cadastrado",
  validateDocumentNumberUnique: "Documento já cadastrado",
  validateEmailUnique: "E-mail já cadastrado",
  noData: "Sem dados",
  emptyStateEssayPhotos: "Este ensaio não possui fotos",
  emptyStateEssayVideos: "Este ensaio não possui vídeos",
  "profile.1": "Modelo",
  "profile.2": "Fotógrafo",
  profilesMostFolloweds: "Perfis mais curtidos",
  posts: "Postagens",
  loadingEssayPhotos: "Carregando fotos ...",
  loadingEssayVideos: "Carregando vídeos ...",
  loadingProfiles: "Carregando perfis ...",
  phoneProfile: "Desejo informar meu WhatsApp no perfil.",
  phoneChanged: "Telefone Alterado",
  planChanged: "Plano Alterado",
  planCanceled: "Plano Cancelado",
  purchasePlan: "Adquirir Plano",
  purchasedPlan: "Pedido enviado com sucesso!",
  paymentMethodChanged: "Forma de pagamento alterada",
  Mensal: "Mensal",
  Trimestral: "Trimestral",
  Anual: "Anual",
  Vip: "Vip",
  errorChangePlan:
    "Não foi possível alterar/adquirir plano. Por favor, tente novamente mais tarde.",
  addCoupon: "Adicionar cupom",
  coupon: "Cupom",
  couponApplied: "Cupom aplicado!",
  aplly: "Aplicar",
  errorCoupon: "Cupom não encontrado ou inválido.",
  planUserFooterBillet:
    "Acesso exclusivo a ensaios completos e feed de postagens.",
  errorCreateSignatureWirecard:
    "Não foi possível criar uma assinatura. Acesse seu perfil e/ou tente novamente mais tarde.",
  notificationsTitle: "Notificações",
  notificationTitleMenu: "Últimas notificações",
  notificationsLink: "Ver todas",
  loadMore: "Carregar mais",
  minCharacter_3: "Nome de usuário deve ter no mínimo 3 caracteres",
  validateUsernameUnique: "Nome de usuário já cadastrado",
  errorUsername:
    "Apenas letras minúsculas, sem espaços e sem caracteres especiais",
  errorNickname: "Apenas letras e espaços",
  username: "Nome de usuário",
  cancelingPlan: "Aguardando Cancelamento",
  changePaymentMethod: "Deseja voltar e trocar o meio de pagamento?",
  subscriptionInstantCancel: "Trocar forma de pagamento?",
  subscriptionCanceled: "Assinatura Cancelada",
  confirm: "Confirmar",
  waitingCancel1: "Seu acesso continua ativo até",
  waitingCancel2: "Aguarde para adquirir um novo plano.",
  change: "Alterar",
  newSubscription: "Criar nova assinatura",
  inactivePlanMessage: "Você não tem nenhum plano ativo",
  inactiveSubscriptionErrorTitle: "Acesso exclusivo para assinantes",
  inactiveSubscriptionErrorText:
    "Você não possui uma assinatura ativa. Adquira um plano para continuar.",
  sharedPost: "Post compartilhado com sucesso!",
  tokenConfirmed: "Token confirmado com sucesso!",
  planCanceledWithSuccess: "Plano cancelado com sucesso!",
  descriptionAcceptedTerms: `Ao selecionar "Aceito os Termos e Privacidade", você concorda em iniciar a assinatura imediatamente e que o pagamento mensal, trimestral, semestral ou anual não é reembolsável. Cobraremos o valor do plano selecionado de forma recorrente no cartão de crédito. Você pode cancelar quando quiser e o cancelamento entrará em vigor no final do período de cobrança.`,
  "notification.NEW_ESSAY": "Ensaio",
  "notification.PAYMENT": "Pagamento",
  "notification.COMMENT_REPLY": "Comentário",
  "notification.PAYMENTNEW_LIKE": "Curtiu",
  "notification.NEW_COMMENT": "Publicação",
  "notification.NEW_FOLLOWER": "Segiu",
  "notification.NEW_POST": "Feed",
}
