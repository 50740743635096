import ptBR from "@locales/pt_BR"
import React, { useState } from "react"
import { IntlProvider } from "react-intl"

const TranslateWrapper = ({ children }) => {
  const [locale] = useState("pt")
  const [messages] = useState(ptBR)

  return (
    <IntlProvider messages={messages} locale={locale}>
      {children}
    </IntlProvider>
  )
}

export default TranslateWrapper
